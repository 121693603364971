<template>
  <q-page padding>
    <q-toolbar class="column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Financeiro</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/" />
        <q-breadcrumbs-el label="Financeiro" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <list-financial v-if="user.profile === 'afiliado'" />
  </q-page>
</template>

<script>
import ListFinancial from '../../components/affiliate/ListFinancial.vue';
export default {
  components: { ListFinancial },
  name: 'Financial'
};
</script>

<style></style>
